<template>
	<div id="rootVM" v-cloak>
		<div id="pageTitle">
			<b>API 호출 오류 로그 조회</b>
		</div>
		<!-- Search -->
		<div class="panel panel-flat">
			<div class="panel-body" style="margin: 0px 20px -20px auto; padding: 20px 0px">
				<div class="row">
					<form action="#" @submit.prevent="search('searchBtnClick')">
						<div class="row col-md-11">
							<!-- API코드(명) -->
							<div class="col-md-4">
								<div class="form-group">
									<label class="col-md-2" align="right">{{ detailsFieldMap.apiNm }}</label>
									<select2 v-model="searchVM.errOccurApiCd" :options="options.apiCds">
										<option value="">전체</option>
									</select2>
								</div>
							</div>
							<!-- 발생 일시 -->
							<div class="row col-md-5">
								<label class="col-md-2 title" align="right" style="margin-top: 8px">
									{{ detailsFieldMap.errOccurDtm }}
								</label>
								<div class="row col-md-9 form-group">
									<div class="col-md-6">
										<input
											type="date"
											class="form-control"
											v-model="searchVM.errOccurDtm"
											field="detailsItem.errOccurDtm"
											data-vv-name="detailsItem.errOccurDtm"
											style="margin-right: 5px"
											:min="minDay"
										/>
									</div>
									<input id="hour" type="text" class="form-control" maxlength="2" v-model="time.hour" />
									<span>시</span>
									<input id="minute" type="text" class="form-control" maxlength="2" v-model="time.minute" />
									<span>분</span>
									<input id="second" type="text" class="form-control" maxlength="2" v-model="time.second" />
									<span>초</span>
								</div>
							</div>
							<!-- 호출 시스템 -->
							<div class="col-md-3">
								<div class="form-group">
									<label>{{ detailsFieldMap.callSysNm }}</label>
									<select2 v-model="searchVM.callSysCd" :options="options.callSysCds">
										<option value="">전체</option>
									</select2>
								</div>
							</div>
						</div>
						<!-- 검색 버튼 -->
						<div class="mb-10 text-right">
							<button type="submit" class="btn btn-labeled bg-primary">
								<b><i class="icon-search4"></i></b>
								검색
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>

		<!-- Grid -->
		<div>
			<div class="mb-10 text-right">
				<button type="button" class="btn btn-labeled bg-teal mx-sm-1" @click="downloadExcel">
					<b><i class="icon-file-excel"></i></b>
					엑셀다운로드
				</button>
			</div>

			<div v-if="firstSearchOptionApplied">
				<KendoGrid
					ref="grid"
					:auto-bind="true"
					:api-url="apiUrl.pageListApi"
					:columns="gridColumns"
					:apply-search-condition="applySearchStateOnGridLoad"
					@selected-row-item-changed="selectedRowItemChanged"
				></KendoGrid>
			</div>

			<div class="mt-10 mb-15 text-right"></div>
		</div>

		<!-- Details -->
		<form id="detailsForm" action="#" class="form-horizontal form-validate-jquery" v-show="isDetailMode">
			<div class="panel panel-flat">
				<div class="panel-heading">
					<h6 class="panel-title">
						<i class="icon-checkmark3 position-left"></i>
						<b>상세정보</b>
					</h6>
					<div class="heading-elements">
						<ul class="icons-list">
							<li><a @click="pannelHidden" data-action="collapse"></a></li>
						</ul>
					</div>
				</div>
				<div class="panel-body">
					<div class="row in-panel-body">
						<fieldset>
							<legend class="text-semibold">
								<i class="icon-cog3 position-left"></i>
								API 호출 오류 로그 정보
							</legend>
							<div class="col-lg-12">
								<!-- api명 -->
								<div class="row col-md-4">
									<label class="col-md-3 title" align="right" style="margin-top: 8px">{{ detailsFieldMap.apiNm }}:</label>
									<div class="form-group">
										<input type="text" class="form-control" style="width: 280px" v-model="detailsItem.errOccurApiNm" :disabled="true" />
									</div>
								</div>
								<!-- 발생일시 -->
								<div class="row col-md-3">
									<label class="col-md-4 title" align="right" style="margin-top: 8px">{{ detailsFieldMap.errOccurDtm }}:</label>
									<div class="row col-md-8 form-group">
										<input type="text" class="form-control" v-model="detailsItem.errOccurDtm" :disabled="true" />
									</div>
								</div>
								<!-- 발생순번 -->
								<div class="row col-md-2">
									<label class="col-md-6 title" align="right" style="margin-top: 8px">{{ detailsFieldMap.errOccurSeq }}:</label>
									<div class="row col-md-6 form-group">
										<input type="text" class="form-control" v-model="detailsItem.errOccurSeq" :disabled="true" />
									</div>
								</div>
								<!-- 호출 시스템명 -->
								<div class="row col-md-3">
									<label class="col-md-4 title" align="right" style="margin-top: 8px">{{ detailsFieldMap.callSysNm }}:</label>
									<div class="row col-md-8 form-group">
										<input type="text" class="form-control" style="width: 200px" v-model="detailsItem.callSysNm" :disabled="true" />
									</div>
								</div>
							</div>

							<div class="row col-lg-12">
								<!-- 업무 오류 내용 -->
								<div class="row col-md-12">
									<label class="col-md-1 title" style="margin-top: 8px; padding: 0px">{{ detailsFieldMap.bizErrCont }}:</label>
									<textarea
										class="col-md-11"
										rows="5"
										cols="50"
										v-model="detailsItem.bizErrCont"
										:disabled="true"
										field="detailsItem.bizErrCont"
										data-vv-name="detailsItem.bizErrCont"
										style="margin-bottom: 18px; resize: none"
									></textarea>
								</div>
							</div>
							<div class="row col-lg-12">
								<!-- 시스템 오류 코드-->
								<label class="col-md-1 title" style="margin-top: 8px; padding: 0px">{{ detailsFieldMap.sysErrCd }}:</label>
								<div class="row col-md-11">
									<div class="row col-md-12 form-group">
										<input type="text" class="form-control" v-model="detailsItem.sysErrCd" :disabled="true" />
									</div>
								</div>
							</div>
							<!-- 시스템 오류 내용-->
							<div class="row col-lg-12">
								<div class="row col-md-12">
									<label class="col-md-1 title" style="margin-top: 8px; padding: 0px">{{ detailsFieldMap.sysErrCont }}:</label>
									<textarea
										class="col-md-11"
										rows="12"
										cols="50"
										v-model="detailsItem.sysErrCont"
										:disabled="true"
										field="detailsItem.sysErrCont"
										data-vv-name="detailsItem.sysErrCont"
										style="margin-bottom: 18px; resize: none"
									></textarea>
								</div>
							</div>
						</fieldset>
					</div>
					<span v-if="debug">
						<br />
						detailsItem: {{ detailsItem }}
						<br />
						errors: {{ errors }}
						<br />
					</span>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
import KendoGrid from '@/components/KendoGrid';
import Select2 from '@/components/Select2';
import _ from 'lodash';
import moment from 'moment';
// import JarvisField from '@/components/JarvisField';
import apiIndex from '../../api/index';

let axiosExtention;
const apiCallErrLogApi = apiIndex.apiCallErrLog;

export default {
	components: {
		Select2: Select2,
		// 'jarvis-field': JarvisField,
		KendoGrid: KendoGrid,
	},
	created() {
		this.pageParam = JSON.parse(sessionStorage.getItem('apiCallErrLogPageParam'));
		if (!this.pageParam) location.href = apiIndex.mainUrl;
		// 기존 라이브러리를 사용하기 위해 변환
		this.pageParam.apiCds.forEach(e => {
			e.text = e.cdNm;
			e.value = e.cd;
			this.$set(this.apiNmAndCdMap, e.text, e.value);
		});
		this.pageParam.callSysCds.forEach(e => {
			e.text = e.cdNm;
			e.value = e.cd;
		});
		this.options = {
			apiCds: this.pageParam.apiCds,
			callSysCds: this.pageParam.callSysCds,
		};

		this.$set(this.searchVM, 'errOccurDtm', moment().format('YYYY-MM-DD'));
		this.minDay = moment().add(-process.env.VUE_APP_MAX_MONTH, 'month').format('YYYY-MM-DD');
		this.time.hour = '08';
		this.time.minute = '00';
		this.time.second = '00';
		axiosExtention = this.$jarvisExtention.axiosExtention;
	},
	mounted() {
		this.$nextTick(function () {
			document.getElementById('hour').focus();
			this.search();
			this.firstSearchOptionApplied = true;
		});
	},
	data: () => ({
		pageParam: null,
		//API목록
		apiUrl: {
			pageListApi: apiCallErrLogApi.inqApiCallErrLog,
			detailsApi: apiCallErrLogApi.inqOneApiCallErrLog,
			excelApi: apiCallErrLogApi.exlApiCallErrLog,
		},
		//검색VM
		searchVM: {
			errOccurApiCd: null,
			errOccurDtm: null,
			callSysCd: null,
		},
		options: {},
		searchState: {}, //적용된 검색조건
		//그리드설정
		gridColumns: [
			{ field: 'errOccurSeq', title: '발생순번', width: '5%', align: 'right' },
			{ field: 'errOccurDtm', title: '발생일시', width: '15%', dataFormat: 'YYYY-MM-DD HH:mm:ss', align: 'center' },
			{ field: 'errOccurApiNm', title: 'API명', width: '20%' },
			{
				field: 'bizErrCont',
				width: '20%',
				title: '업무오류내용',
				overFlowHidden: true,
			},
			{
				field: 'sysErrCd',
				width: '20%',
				title: '시스템오류코드',
				overFlowHidden: true,
			},
			{
				field: 'callSysNm',
				width: '20%',
				title: '호출시스템',
			},
		],
		//상세필드명맵
		detailsFieldMap: {
			apiNm: 'API명',
			errOccurDtm: '발생일시',
			callSysNm: '호출시스템',
			bizErrCont: '업무오류내용',
			sysErrCd: '시스템오류코드',
			errOccurSeq: '발생순번',
			sysErrCont: '시스템오류내용',
		},
		//인풋 최댓값 설정
		detailsItem: {}, //상세정보
		//api명 -> 코드 확인용
		apiNmAndCdMap: {},
		time: {
			hour: '',
			minute: '',
			second: '',
		},
		firstSearchOptionApplied: false,
		debug: false,
		minDay: '',
	}),
	computed: {
		isDetailMode() {
			return !_.isEmpty(this.detailsItem);
		},
	},
	watch: {
		detailsItem(newVal) {
			//details활성화 시 스크롤 이동처리
			if (!_.isEmpty(newVal)) {
				this.$nextTick(function () {
					window.scrollTo({ top: this.getDetailsFormPositionForScrolling(), behavior: 'smooth' });
				});
			}
		},
	},
	methods: {
		getDetailsFormPositionForScrolling() {
			let element = document.getElementById('detailsForm');
			return element.getBoundingClientRect().top + window.scrollY;
		},
		isNotNumeric(input) {
			let checkNum = /[0-9]/;
			let strInput = String(input);

			if (checkNum.test(strInput) == false) {
				return true;
			}
			return false;
		},
		pannelHidden(e) {
			// click event 에서 target pannel 가져오기
			let pannel = $(e.target).closest('.panel').find('.panel-body')[0];
			if (pannel) {
				if (pannel.style.display === 'none') {
					e.target.classList.remove('rotate-180');
					pannel.style.display = 'block';
				} else {
					e.target.classList.add('rotate-180');
					pannel.style.display = 'none';
				}
			}
		},
		isValidTime(hour, minute, second) {
			if (this.isNotNumeric(hour) || this.isNotNumeric(minute) || this.isNotNumeric(second) || hour > 23 || minute > 59 || second > 59) {
				return false;
			}
			return true;
		},
		checkAndMakeTime() {
			if (this.time.hour != '' || this.time.minute != '' || this.time.second != '') {
				if (this.time.hour == '') this.time.hour = '0';
				if (this.time.minute == '') this.time.minute = '0';
				if (this.time.second == '') this.time.second = '0';
				if (this.isValidTime(this.time.hour, this.time.minute, this.time.second) == false) {
					alert('유효한 시간을 입력해주세요.');
					return false;
				}
				this.searchVM.errOccurDtm = this.searchVM.errOccurDtm + ' ' + this.time.hour + ':' + this.time.minute + ':' + this.time.second;
			}
			return true;
		},
		//검색적용
		search(flag) {
			let calendar = this.searchVM.errOccurDtm;
			if (this.checkAndMakeTime() == false) {
				return;
			}
			this.searchState = $.extend(true /*deep*/, {}, this.searchVM);
			if (flag == 'searchBtnClick') {
				this.loadGrid();
			}
			this.searchVM.errOccurDtm = calendar;
		},
		//그리드로드
		loadGrid() {
			this.closeDetails();
			this.$refs.grid.load();
		},
		//그리드로드시 검색조건적용
		applySearchStateOnGridLoad(param) {
			return $.extend(true /*deep*/, param, this.searchState || {});
		},
		//엑셀다운로드
		downloadExcel() {
			this.$axios({
				url: this.apiUrl.excelApi,
				method: 'POST',
				data: this.searchState,
				responseType: 'blob', // important
			})
				.then(response => {
					let date = new Date();
					// 저장 파일 명 생성
					let fileName =
						'API호출오류로그_' + date.getFullYear() + ('0' + (1 + date.getMonth())).slice(-2) + ('0' + date.getDate()).slice(-2) + '.xlsx';
					const url = window.URL.createObjectURL(new Blob([response.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', fileName);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
				})
				.catch(axiosExtention.buildErrorHandler());
		},
		//그리드선택처리
		selectedRowItemChanged(selectedRowItem) {
			let apiNm = selectedRowItem.errOccurApiNm;
			if (!_.isEmpty(selectedRowItem)) {
				this.$axios
					.post(this.apiUrl.detailsApi, {
						errOccurApiCd: this.apiNmAndCdMap[apiNm],
						errOccurDtm: selectedRowItem.errOccurDtm,
						errOccurSeq: selectedRowItem.errOccurSeq,
					})
					.then(
						function (response) {
							this.detailsItem = $.extend(true, {}, response.data);
						}.bind(this),
					)
					.catch(axiosExtention.buildErrorHandler());
			} else {
				//상세화면 닫기 (다시로드등으로 선택변경)
				this.closeDetails();
			}
		},
		//상세화면 닫기
		closeDetails() {
			this.detailsItemOriginal = {};
			this.detailsItem = {};
		},
	},
};
</script>

<style scoped>
[v-cloak] > * {
	display: none !important;
}

#rootVM > .panel .panel-body .form-group {
	display: flex;
	align-items: center;
}
#rootVM > .panel .panel-body .form-group label {
	width: 100%;
	margin-top: 5px;
	max-width: 200px;
}

.table th {
	background: #eee;
	vertical-align: middle !important;
	font-weight: bold;
}
.panel-body .table th:first-child {
	width: 120px;
	text-align: center;
}

.table td {
	vertical-align: middle;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	max-width: 1px;
}

.panel-body {
	text-align: center;
}

.panel-body .table td:first-child {
	text-align: center;
}
.panel-body .table td:last-child {
	text-align: center;
	width: 80px;
}

.in-panel-body {
	display: inline-block;
	width: 1200px;
}
</style>
